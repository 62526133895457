(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define([], factory);
	else if(typeof exports === 'object')
		exports["EdgePlayer"] = factory();
	else
		root["EdgePlayer"] = root["EdgePlayer"] || {}, root["EdgePlayer"]["eplayerAPI"] = factory();
})(self, () => {
return 