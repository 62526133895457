import { EventEmitter } from 'events';

export default class EplayerAPI extends EventEmitter {

  constructor(element) {
    super();
    if (!element.contentWindow) {
      throw 'Iframe is not valid';
    }
    this.onMsgSend = (evt) => {
      this.getMessage(evt);
    }

    this.element = element;
    this.initPostMessage();
  }

  initPostMessage() {
    if (this.element.addEventListener) {
      window.addEventListener('message', this.onMsgSend, false);
    } else {
      // IE8
      window.attachEvent('onmessage', this.onMsgSend);
    }
    this.element.contentWindow.postMessage({
      event: 'apiInit',
      origin: this.element.src,
      params: null
    }, this.element.src);

  }

  destroy() {
    if (this.element && this.element.removeEventListener) {
      window.removeEventListener('message', this.onMsgSend);
    } else if (this.element) {
      // IE8
      window.detachEvent('onmessage', this.onMsgSend);
    }
  }

  deviceOrientationRequest() {
    if (window.DeviceOrientationEvent && typeof DeviceOrientationEvent.requestPermission === 'function') {
      DeviceOrientationEvent.requestPermission()
        .then(response => {
          if (response === 'granted') {
            this.deviceOriantationEvent();
          }
        }).catch((e) => {
          console.error(e);
        });
    } else {
      this.deviceOriantationEvent();
    }
  }

  deviceOriantationEvent() {
    window.onorientationchange = function () {
      this.method({
        name: 'orientationchange',
        origin: this.element.src,
        params: {
          event: window.orientation
        }
      });
    }.bind(this);
    window.ondeviceorientation = function (e) {
      this.method({
        name: 'deviceorientation',
        origin: this.element.src,
        params: {
          event: {
            'alpha': e.alpha,
            'beta': e.beta,
            'gamma': e.gamma,
          }
        }
      });
    }.bind(this);
    if (this.isIOS) {
      window.ondevicemotion = function (e) {
        this.method({
          name: 'devicemotion',
          origin: this.element.src,
          params: {
            event: this.cloneDeviceMotionEvent(e),
          }
        });
      }.bind(this);
    }
  }

  isIOS() {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  }

  cloneDeviceMotionEvent(e) {
    return {
      acceleration: {
        x: e.acceleration.x,
        y: e.acceleration.y,
        z: e.acceleration.z,
      },
      accelerationIncludingGravity: {
        x: e.accelerationIncludingGravity.x,
        y: e.accelerationIncludingGravity.y,
        z: e.accelerationIncludingGravity.z,
      },
      rotationRate: {
        alpha: e.rotationRate.alpha,
        beta: e.rotationRate.beta,
        gamma: e.rotationRate.gamma,
      },
      interval: e.interval
    };
  }

  getMessage(e) {
    //TODO убрать это, когдаа все переключатся e.data.origin == 'eplayer'
    if (e.data.origin && (e.data.origin === this.element.src || e.data.origin === 'eplayer')) {
      if (e.data.event) {
        if (e.data.event === 'ready') {
          this.readyConversation = true;
          this.emit('ready');
        } else if (e.data.event === 'getDeviceOrientation') {
          this.deviceOrientationRequest();
        } else {
          this.emit(e.data.event, e.data.params);
        }

      }
      if (e.data.method) {
        try {
          if (e.data.method === 'isApiLibraryLoaded') {
            this.element.contentWindow.postMessage({
              event: 'yesItsLoaded',
              params: null,
              origin: this.element.src
            }, this.element.src);
            return;
          }
          if (this.callbacks && this.callbacks[e.data.method]) {
            for (let i = 0; i < this.callbacks[e.data.method].length; i++) {
              this.callbacks[e.data.method][i](e.data.res);
            }
            this.callbacks[e.data.method] = [];
          }
        } catch (e) {
          console.error(e);
        }

      }
    }
  }

  method({ name, params, callback }) {
    if (this.readyConversation) {
      this.element.contentWindow.postMessage({
        method: name,
        params: params,
        origin: this.element.src
      }, this.element.src);
      if (callback) {
        if (!this.callbacks) {
          this.callbacks = {};
        }
        if (!this.callbacks[name]) {
          this.callbacks[name] = [];
        }
        this.callbacks[name].push(callback);
      }

    }
  }
}
